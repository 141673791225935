<template>
  <section>
    <PanelHeaderEquipmentList
      v-bind:title="title"
      v-bind:nav="nav"
      icon="fa fa-dashboard"
      :connector_id="connectorId"
      @tab="tab = $event"
    />
    <div
      class="tab-pane"
      role="tabpanel"
      aria-labelledby=""
      id="edit"
      :class="tab == '#edit' ? 'active' : ''"
    >
      <FormData
        ref="formData"
        v-bind:connector_id="connector_id"
        v-bind:device_id="device_id"
        v-bind:data_id="data_id"
        v-on:titleChanged="onTitleChanged"
        v-on:navChanged="onNavChanged"
        v-on:noData="$emit('noData')"
        :key="formDataKey"
        @loading="(state) => this.$emit('loading', state)"
        @updateKey="(key) => (formDataKey = key || Date.now())"
        @alarmEnabled="alarmEnabled = $event"
      />
    </div>
    <div
      class="tab-pane tab-pane-list"
      role="tabpanel"
      aria-labelledby=""
      v-if="dataId && alarmEnabled"
      style="padding: 0; margin: 0"
      id="list"
      :class="tab == '#list' ? 'active' : ''"
    >
      <DashboardEditAlarmPicker
        v-if="data_id"
        :key="importCounter.alarm"
        :connector_id="connector_id"
        :device_id="device_id"
        :data_id="data_id"
        :dataList="dataList"
        :showEmptyPanel="true"
        :header="false"
        @resource_imported="resourceImported"
      />
    </div>
  </section>
</template>
<script>
import PanelHeaderEquipmentList from "@/components/panel-header-equipment-list.vue";
import FormData from "@/components/registration/form-data.vue";
import DashboardEditAlarmPicker from "@/components/registration/dashboard-edit-alarm-picker.vue";
import DashboardEditFormBase from "@/components/registration/dashboard-edit-form-base.vue";
export default {
  name: "DashboardEditDataForm",
  extends: DashboardEditFormBase,
  components: {
    FormData,
    DashboardEditAlarmPicker,
    PanelHeaderEquipmentList
  },
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    },
    device_id: {
      type: Number,
      required: true,
      default: 0
    },
    data_id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      title: "loading",
      tab: "",
      nav: null,
      showNextLevel: false,
      formDataKey: this.data_id,
      importCounter: {
        data: 0,
        alarm: 0
      },
      alarmEnabled: false
    };
  },
  computed: {
    connectorId() {
      return parseInt(
        this.connector_id || this.$route.params.connector_id || 0
      );
    },
    deviceId() {
      return parseInt(this.device_id || this.$route.params.device_id || 0);
    },
    dataId() {
      return parseInt(this.data_id || this.$route.params.data_id || 0);
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"].filter(({clp_id}) =>
        this.connectorId ? parseInt(clp_id) == this.connectorId : true
      );
    }
  },
  methods: {
    onTitleChanged(title) {
      // this.$emit("titleChanged", this.$tc("data") + ": " + title);
      this.title = this.$tc("data") + ": " + title;
    },
    onNavChanged(nav) {
      if (this.dataId) {
        nav = nav || {};
        nav.showTabLabels = true;
        nav.tabs = {items: []};
        if (this.alarmEnabled) {
          nav.tabs.items.push({
            id: "list",
            icon: "fa fa-bell",
            title: "alarm_list",
            label: this.$tc("tab_label.alarm", 2)
          });
        }
        nav.tabs.items.push({
          id: "edit",
          icon: "glyphicon glyphicon-stats",
          title: "edit",
          label: this.$tc("tab_label.data", 1)
        });
      }
      // this.$emit("navChanged", nav);
      this.$set(this, "nav", nav);
    },
    resourceImported(entity) {
      this.importCounter[entity] += 1;
    }
  },
  mounted() {},
  beforeCreate() {
    this.$emit("rule", "DadoEscrita");
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 30px 0 200px 0;
  padding: 10px 10px 50px 10px;
}

section > .tab-pane-list {
  padding: 0;
}
</style>
